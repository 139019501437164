import { createModuleApi, Course, createCourseDetail, UserCourseNote } from 'momai'
import { ref } from 'vue'

const courseApi = <T>() => createModuleApi<T>('courseinfo')
const courseNoteApi = <T>() => createModuleApi<T>('userCourseNote')

const detail = (id: number) => courseApi<Course>()({
  subUrl: `info/${id}`
}).then()

const update = (course: Course) => courseApi<string>()({
  subUrl: 'update',
  method: 'post',
  params: course
})

const save = (course: Course) => courseApi<string>()({
  subUrl: 'save',
  method: 'post',
  params: course
})

const del = (id: number) => courseApi<string>()({
  subUrl: '/delete',
  method: 'post',
  params: [id]
})

const useFetchDetail = ({
  courseId
}: {
  courseId: number;
}) => {
  const courseDetail = ref<Course>(createCourseDetail())
  detail(courseId).then(r => {
    courseDetail.value = r
  })
  return {
    courseDetail
  }
}

const fetchNote = (courseId: number) => courseNoteApi<UserCourseNote | null>()({
  subUrl: `${courseId}/myNote`
})

const addNote = (courseNote: UserCourseNote) => courseNoteApi<string>()({
  subUrl: 'saveCourseNote',
  params: courseNote,
  method: 'post'
})

const listByCourseSeriesId = (courseSeriesId: number) => courseApi<Course[]>()({
  subUrl: 'listAll',
  method: 'post',
  params: {
    courseId: courseSeriesId
  }
})

const listByCourseSeriesIdAdmin = (courseSeriesId: number) => courseApi<Course[]>()({
  subUrl: 'adminListAll',
  method: 'post',
  params: {
    courseId: courseSeriesId
  }
})

export {
  listByCourseSeriesId,
  listByCourseSeriesIdAdmin,
  del,
  fetchNote,
  addNote,
  save,
  useFetchDetail,
  update,
  detail
}
