import { ref, Ref } from 'vue'
import useUploadVideo from '@/utils/useUploadVideo'
import { Course } from 'momai'

export default (courseDetail: Ref<Course>) => {
  const videoUploader = ref<HTMLInputElement | null>(null)
  const homeworkVideoUploader = ref<HTMLInputElement | null>(null)
  const uploadDirectory = 'course'
  return {
    videoUploader,
    courseVideo: useUploadVideo({
      picker: videoUploader,
      uploadDirectory,
      uploadFinished: (videoUrl, coverUrl, duration) => {
        courseDetail.value.video = videoUrl.value
        courseDetail.value.videoCover = coverUrl.value
        courseDetail.value.videoLength = String(duration.value)
      }
    }),
    homeworkVideoUploader,
    homeworkVideo: useUploadVideo({
      picker: homeworkVideoUploader,
      uploadDirectory,
      uploadFinished: (videoUrl, coverUrl, duration) => {
        courseDetail.value.exampleVideo = videoUrl.value
        courseDetail.value.exampleVideoCover = coverUrl.value
        courseDetail.value.exampleVideoLength = String(duration.value)
      }
    })
  }
}
