import { Course } from 'momai'
import { Ref } from 'vue'
import { Toast } from 'vant'

export default (courseInfo: Ref<Course>) => {
  const validate = (): boolean => {
    const info = courseInfo.value
    if (!info.name) {
      Toast.fail('请输入课程名称')
      return false
    }
    if (!info.desc) {
      Toast.fail('请输入课程简介')
      return false
    }
    if (!info.img) {
      Toast.fail('请上传课程缩略图')
      return false
    }
    if (!info.video) {
      Toast.fail('请上传课程视频')
      return false
    }
    if (!info.detailImg) {
      Toast.fail('请上传课程图文')
      return false
    }
    if (!info.workRequire) {
      Toast.fail('请输入作业要求')
      return false
    }
    if (!info.exampleVideo) {
      Toast.fail('请输入作业视频样例')
      return false
    }
    return true
  }
  return {
    validate
  }
}
