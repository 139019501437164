
import useVideoUploader from './useVideoUploader'
import { defineComponent, PropType, ref, watch } from 'vue'
import { Course } from 'momai'
import MomaiCoverUploaderSpecial from '@/components/MomaiCoverUploaderSpecial.vue'

export default defineComponent({
  components: { MomaiCoverUploaderSpecial },
  props: {
    value: {
      type: Object as PropType<Course>,
      required: true
    }
  },

  setup: (props, { emit }) => {
    const courseDetail = ref(props.value)
    watch(() => props.value, (newValue) => {
      courseDetail.value = newValue
    }, {
      deep: true
    })
    watch(courseDetail, newCourseDetail => {
      emit('update:value', newCourseDetail)
    }, {
      deep: true
    })
    return {
      courseDetail,
      ...useVideoUploader(courseDetail)
    }
  }
})
