
import { Notify } from 'vant'
import CourseForm from '../Form.vue'
import { createCourse, Course } from 'momai'
import { ref } from 'vue'
import { save } from '@/api/entity/course'
import { goBack } from '@/states/layout'
import { useRoute } from 'vue-router'
import useCourseValidate from '../useCourseValidate'

export default {
  components: { CourseForm },
  setup: () => {
    const { courseSeriesId } = useRoute().params
    const courseDetail = ref<Course>(createCourse(Number(courseSeriesId)))
    const { validate } = useCourseValidate(courseDetail)
    return {
      courseDetail,
      doAdd: () => {
        if (validate()) {
          save(courseDetail.value).then(() => {
            goBack()
            Notify({
              type: 'success',
              message: '课程添加成功！'
            })
          })
        }
      }
    }
  }
}
